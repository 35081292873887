import { request } from "@/request/http";

export default {
  getAllProjectByCourseidWorkStat: (data) => {
    return request("POST", `/index.php/backother/getAllProjectByCourseidWorkStat`, data);
  },

  getOpenDateWorkStat: (data) => {
    return request("POST", `/index.php/backother/getOpenDateWorkStat`, data);
  },

  getCourseList: (data) => {
    return request("POST", `/index.php/backother/getCourseList`, data);
  },
};