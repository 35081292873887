<template >

    <div class="logList">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程工作量统计" name="first">
                <!-- 筛选div -->
                <div class="selectVir">
                    <div style="float:left">
                        <el-select v-model="course_id" placeholder="请选择课程！" clearable class="setInput width350">
                            <el-option v-for="(item, index) in courseList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                        <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        <!-- 不点统计无法导出 -->
                        <el-button class="chartsButton" @click="getAllProjectByCourseidWorkStat">统计</el-button>
                    </div>
                    <div style="float:right">
                        人时数总计：{{courseTotal}}
                    </div>
                </div>
                <!-- 表格div -->
                <div v-for="item in projectDataList" :key="item.id" >
                    <!-- 标题 -->
                    <div class="title">
                        {{item.name}}
                    </div>
                    <!-- 表格 -->
                    <div >
                        <el-table
                        :data="item.info"
                        style="width: 100%">
                            <el-table-column
                                prop="name"
                                label="人员"
                                width="300">
                            </el-table-column>
                            <el-table-column
                                prop="ping_ri"
                                label="单元数"
                                width="300">
                            </el-table-column>
                            <el-table-column
                                prop="zrsjs"
                                label="人数"
                                width="300">
                            </el-table-column>
                            <el-table-column
                                prop="zrsjs"
                                label="人学时数"
                                width="300">
                                <template slot-scope="scope">
                                    {{scope.row.hours * scope.row.zrsjs}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="danyuanbaifenbi"
                                label="单元百分比">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="个人工作量统计" name="second">
                <!-- 筛选div -->
                <div class="selectVir">
                    <div style="float:left">
                        <el-date-picker v-model="openDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        <!-- 不点统计无法导出 -->
                        <el-button class="chartsButton" @click="getOpenDateWorkStat">统计</el-button>
                    </div>
                    <div style="float:right">
                        人时数总计：{{openTotal}}
                    </div>
                </div>
                <!-- 表格div -->
                <div >
                    <el-table
                    :data="openDataList"
                    style="width: 100%">
                        <el-table-column
                            prop="name"
                            label="项目名称"
                            width="400">
                        </el-table-column>
                        <el-table-column
                            prop="ping_ri"
                            label="单元数"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            prop="zrsjs"
                            label="人数">
                        </el-table-column>
                        <el-table-column
                            prop="hours"
                            label="学时数">
                        </el-table-column>
                        <el-table-column
                            prop="hoursPerson"
                            label="人时数">
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    // 引入试题列表js
    import logList from "@/assets/js/teacher/other/workStatPage.js";
    export default {
        ...logList
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/teacher/other/workStatPage.scss";
</style>